
.simple-facility-card {
  display: grid;
  grid-template-columns: calc(var(--grid-unit) * 10) 1fr calc(var(--grid-unit) * 3);
  gap: calc(var(--grid-unit) * 2);
  padding-right: calc(var(--grid-unit) * 2);
  overflow: hidden;
  text-align: left;
  border: var(--border) var(--gray-80);
  border-radius: var(--radius-big);

  &__image {
    width: calc(var(--grid-unit) * 10);
    min-height: 68px;
    background-color: var(--viridian-10);
  }

  &__data {
    padding: calc(var(--grid-unit) * 2) 0;

    & > * {
      margin: 0;
    }
  }

  &__fav {
    align-self: center;
    font-size: calc(var(--grid-unit) * 3);
    color: var(--coral-20);
  }

  &__claim,
  &__edit {
    align-self: center;
    text-align: right;
  }

  &__claim {
    a {
      margin-right: calc(var(--grid-unit) * 1.5);
    }
  }

  &__edit {
    margin-right: calc(var(--grid-unit) * 1.5);

    a:first-of-type {
      margin-right: calc(var(--grid-unit) * 3);
    }
  }

  &--claimable {
    grid-template-columns: calc(var(--grid-unit) * 8) 1fr calc(var(--grid-unit) * 13.25);
    border: var(--border) var(--gray-20);
    box-shadow: 0 5px 10px 0 rgba(223, 223, 223, 0.75);

    .simple-facility-card__image {
      width: 60px;
    }
  }
}


.kForm {
  &__header {
    h1,
    h2,
    h3,
    h4 {
      color: var(--gray-80);
    }
  }

  &__footer {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;

    > * {
      margin-left: var(--grid-unit);
    }
  }

  &__header,
  &__footer,
  .kFieldset::v-deep {
    &:first-child {
      margin-top: 0;
    }

    &:last-child {
      padding-bottom: 0;
      margin-bottom: 0;
      border-bottom: none;
    }

    padding-bottom: calc(var(--grid-unit) * 3);
    margin: calc(var(--grid-unit) * 3) 0;
    border-bottom: 1px solid var(--gray-10);
  }
}


.verification-container {
  text-align: center;
  padding: 20px;

  .instructions {
    margin-bottom: 10px;
    font-size: 16px;

    .phone-number {
      font-weight: bold;
      font-size: 18px;
    }
  }

  .resend-link {
    margin-top: 10px;
    font-size: 14px;
    color: #666;
  }

  .code-input {
    display: flex;
    justify-content: center;
  }
}


.steps {
  position: relative;
  display: flex;
  justify-content: space-between;
  width: 100%;
  max-width: 180px;
  height: 1px;
  margin: calc(var(--grid-unit) * 3) auto;
  background: var(--viridian-10);

  .step-bar {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 1px;
    background-color: var(--gold-30);
  }

  .step-indicator {
    display: block;
    width: 9px;
    height: 9px;
    margin-top: -4px;
    background: var(--viridian-10);
    border-radius: 50%;

    &:last-of-type {
      margin-right: -1px;
    }

    &.completed {
      background-color: var(--gold-30);
    }

    &.active {
      width: 25px;
      height: 25px;
      margin-top: -12px;
      margin-right: -12px;
      margin-left: -12px;
      background-color: unset;
    }
  }
}


::v-deep {
  .page {
    position: unset;
  }
}

.error-message {
  color: var(--rust-40);
}

.bold {
  font-weight: 500;
}

.title {
  color: var(--viridian-50);
  text-align: center;
  margin-bottom: 1.5rem;
}

.sub-title {
  font-size: medium;
  text-align: center;
  font-weight: 400;
  padding: 1rem 0 2rem;
}

.contact {
  margin-top: 1.5rem;
  border-radius: var(--grid-unit);
  border: 1px solid #dbdbdb;
  padding: calc(var(--grid-unit) * 2);
  font-size: 16px;

  &__title {
    font-weight: 500;
    font-family: var(--sans-serif);
  }

  &__info {
    .label {
      display: block;
      margin-bottom: 5px;
      color: var(--gray-40);
    }
  }

  &__info:not(:last-child) {
    margin-bottom: 10px;
  }
}

.note {
  margin-top: 1.5rem;
  color: var(--gold-80);
  background-color: var(--gold-0);
  border: 1px solid var(--gold-50);
  border-radius: var(--grid-unit);
  padding: calc(var(--grid-unit) * 2);
  font-size: calc(var(--grid-unit) * 2);
  font-weight: 400;

  &__title {
    font-weight: 500;
    font-size: 16px;
    font-family: var(--sans-serif);
    color: var(--gold-80);
  }
}

.login-container {
  text-align: center;

  .login-link {
    display: inline-block;
    margin-top: 5px;
    font-size: 16px;
  }
}

.success-note {
  padding: calc(var(--grid-unit) * 2);
  margin-bottom: 2rem;
  background-color: var(--periwinkle-0);
  color: var(--periwinkle-60);
  border-radius: var(--radius);
  border: 1px solid var(--periwinkle-15);
}

.inner-margin:not(:last-child) {
    margin-bottom: var(--grid-unit);
  }

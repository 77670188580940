
.google-btn {
  border: 1px solid var(--gray-20);
  border-radius: 8px;

  &::v-deep .systemBtn__text {
    color: var(--gray-80);
    justify-content: unset;
  }
}

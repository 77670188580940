
.login-signup-modal {
  background-color: var(--modal-bg-color);
  backdrop-filter: blur(8px);
}

.horizontal-line-with-text {
  width: 100%;
  text-align: center;
  border-bottom: 1px solid var(--gray-20);
  line-height: 0.1em;
  margin: calc(var(--grid-unit) * 4) 0 calc(var(--grid-unit) * 4);
}

.horizontal-line-with-text span {
  background:#fff;
  color: var(--gray-60);
  padding:0 calc(var(--grid-unit) * 2);
}

.login__content__google-sso img {
  @media only screen and (max-width: 400px ) {
    margin: 0 calc(var(--grid-unit) * 2) !important;
  }

  @media only screen and (max-width: 360px ) {
    margin: 0 var(--grid-unit) !important;
  }
}

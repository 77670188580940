
/* stylelint-disable-next-line selector-id-pattern */
#registrationModal {
  .full-name-row {
    margin-top: calc(var(--grid-unit) * 3);
    margin-bottom: calc(var(--grid-unit) * 2);
    display: flex;

    .k-form-field__name {
      margin: 0 !important;
    }

    :first-child {
      padding-right: var(--grid-unit) !important;
    }
  }
}

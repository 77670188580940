
.onboardingStep {
  > * {
    margin-bottom: calc(var(--grid-unit) * 2);

    @media screen and (min-width: 992px) {
      margin-bottom: calc(var(--grid-unit) * 3);
    }
  }

  &__heading {
    padding-top: calc(var(--grid-unit) * 2);
    margin-right: auto;
    margin-left: auto;
    text-align: center;
  }
}

.centered-text {
  text-align: center;
}


.verify-options {
  padding: calc(var(--grid-unit) * 2);
  border: var(--border) var(--gray-80);
  border-radius: var(--radius-big);
  display: flex;
  align-items: center;

  &:first-child {
    margin-top: 20px;
  }

  &:last-child {
    margin-bottom: 60px;
  }

  &+.verify-options {
    margin-top: 15px;
  }

  .label-content {
    .label-text {
      font-weight: 500;
      color: var(--gray-80);
    }

    .phone-number {
      font-size: 0.9em;
      color: #888;

      .change-link {
        color: #008066;
        text-decoration: none;
        margin-left: 5px;

        &:hover {
          text-decoration: underline;
        }
      }
    }
  }
}


.page {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: var(--z-navigation);
  height: 100vh;
  min-height: 100%;
  overflow-y: scroll;
  background: var(--viridian-50);

  &--provider {
    background: var(--viridian-0);
    background-image: url('https://res.cloudinary.com/kinside/image/upload/v1669144304/app_assets/register-bg.svg');
    background-repeat: repeat-x;
    background-position: top 388px center;
  }
}

.onboarding {
  &__wrapper {
    display: flex;
    justify-content: center;
    width: 100%;
    height: auto;
    padding: calc(var(--grid-unit) * 7) calc(var(--grid-unit) * 2);

    @media screen and (min-width: 768px) {
      padding: calc(var(--grid-unit) * 3);
    }

    @media screen and (min-width: 992px) {
      padding: calc(var(--grid-unit) * 5);
    }
  }

  &__form {
    position: relative;
    width: 100%;
    max-width: 472px;
    padding: calc(var(--grid-unit) * 2);
    background: var(--gray-0);
    border-radius: 24px 24px 16px 16px;

    @media screen and (min-width: 400px) {
      padding: calc(var(--grid-unit) * 4);
      padding-bottom: calc(var(--grid-unit) * 2);
      margin-top: 100px;
      border-radius: 32px 32px 24px 24px;
    }
  }

  &__footer {
    display: flex;
    flex-direction: column;
    align-items: center;

    .nextPrevButtons {
      justify-content: center;
      margin-top: calc(var(--grid-unit) * 2);
      margin-bottom: calc(var(--grid-unit) * 2);

      > * {
        margin-right: var(--grid-unit);

        &:last-child {
          margin-right: 0;
        }
      }

      .internal-step-counter {
        display: inline-block;
      }
    }

    .nextBtn {
      width: 100%;
      display: flex;
      flex-direction: column;
      gap: 10px;
      align-items: center;

      button {
        display: block;
        width: 100%;
        padding: 10px;
        margin: 0 auto;
      }
    }

    .skipButton {
      margin-top: var(--grid-unit);
    }
  }

  &__illustration {
    display: block;
    width: calc(var(--grid-unit) * 10.5);
    margin: 0 auto;

    @media screen and (min-width: 768px) {
      width: calc(var(--grid-unit) * 20);
      margin: -33% auto 16px;
    }
  }
}


.facility-photo {
  position: relative;
  background-color: var(--gray-5);
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;

  &__attribution {
    position: absolute;
    right: 0;
    bottom: 0;
    padding: calc(var(--grid-unit) / 2);
    font-size: 10px;
    color: var(--gray-0);

    &::v-deep a {
      color: var(--gray-0);
      text-decoration: underline;
    }
  }
}

